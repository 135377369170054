import Vue from 'vue';
import { initiatePingPongLoop } from '../../ping-pong';
import ws from '../../ws';
export default Vue.extend({
    data: function () {
        return {
            userToken: '',
            ws: ws,
            currentState: {},
            startTime: null,
            counts: {
                users: 0,
                responses: 0,
                votes: 0,
                ballots: 0
            },
            results: [],
            alerts: []
        };
    },
    props: {
        jwt: String,
        activity: Object
    },
    beforeMount: function () {
        var _this = this;
        // attach ws event listeners
        this.ws.addEventListener('open', function () {
            // on open, authenticate: use activity_token cookie if present
            //                        otherwise use jwt value
            if (_this.userToken) {
                _this.send({
                    initType: 'userToken',
                    payload: _this.userToken
                });
            }
            else if (_this.jwt) {
                _this.send({
                    initType: 'adminToken',
                    payload: _this.jwt
                });
            }
            else {
                // TODO: what if neither is present?
                console.error('no user token or jwt!');
            }
        });
        this.ws.addEventListener('message', function (message) {
            var _a;
            var data = JSON.parse(message.data);
            console.debug('incoming ws message:', data);
            switch (data.action) {
                case 'set_token': break;
                case 'go_to_state':
                    _this.currentState = data.payload.state;
                    _this.startTime = (_a = data.payload.startTime) !== null && _a !== void 0 ? _a : null;
                    if (_this.currentState.name === 'show_results')
                        _this.results = data.payload.results;
                    break;
                case 'user_count':
                    _this.counts.users = data.payload.count;
                    break;
                case 'response_count':
                    _this.counts.responses = data.payload.count;
                    break;
                case 'vote_count':
                    _this.counts.votes = data.payload.count;
                    break;
                case 'ballots_sent_count':
                    _this.counts.ballots = data.payload.count;
                    break;
                case 'redirect':
                    window.location.href = data.payload.url;
                    break;
                case 'closing_connection':
                    // TODO: flag connection as closed by backend so we don't try to reconnect
                    break;
                default:
                    // if we don't recognize the action but we have a message attribute, this is an error response
                    if (data.message) {
                        _this.notify({
                            status: 'danger',
                            icon: 'x-circle-fill',
                            message: "Something went wrong: " + data.message + " (" + Date.now() + ")"
                        });
                    }
                    else {
                        // TODO: otherwise handle error (or fail silently?)
                        console.error("unknown action: " + data.action);
                    }
            }
        });
        this.ws.addEventListener('close', function (event) {
            // TODO: handle close event: if abnormal close (i.e. not 1001), try to reconnect with exponential backoff
            console.debug(event);
        });
        this.ws.addEventListener('error', function (error) {
            // TODO: handle error: ???
            console.error(error);
        });
        initiatePingPongLoop(this.ws);
    },
    methods: {
        next: function () {
            this.send({
                action: 'advance_state',
                stateProps: this.currentState
                // userToken: this.userToken
            });
            if (this.currentState.question === this.activity.states[this.activity.states.length - 1].question &&
                this.currentState.name === 'show_results') {
                window.location.href = "/activities/" + this.activity.id + "/results";
            }
        },
        newBallot: function () {
            this.send({
                action: 'next_voting_round',
                stateProps: this.currentState
            });
        },
        // ws helpers
        send: function (message) {
            console.debug('outgoing ws message', message);
            this.ws.send(JSON.stringify(message));
        },
        // cookie helpers
        setCookie: function (name, value) {
            document.cookie = name + "=" + value + ";samesite=strict";
        },
        getCookie: function (name) {
            var value = document.cookie
                .split(/;\s*/)
                .find(function (row) { return row.startsWith(name); });
            // if no cookie is found, return an empty string
            if (!value)
                return '';
            // otherwise get the cookie's value and return it
            // note that we can't use value.split('=')[1], since it will split at any number of =
            // characters and we might have some in the cookie's contents (e.g. for base64 encoded
            // values such as the APIGW-generated websocket IDs)
            var i = value.indexOf('=');
            return value.slice(i + 1).trim();
        },
        // alert helpers
        notify: function (alert) {
            this.alerts.push(alert);
        },
        dismiss: function (index) {
            this.alerts.splice(index, 1);
        }
    }
});
