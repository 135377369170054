var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from 'vue';
export default Vue.extend({
    props: {
        id: Number,
        responseID: String,
        ballot: Object
    },
    computed: {
        self: function () {
            if (this.ballot.response_1_id === this.responseID) {
                return __assign(__assign({}, this.ballot.response1), { side: 'left' });
            }
            else {
                return __assign(__assign({}, this.ballot.response2), { side: 'right' });
            }
        },
        other: function () {
            if (this.ballot.response_1_id === this.responseID) {
                return __assign(__assign({}, this.ballot.response2), { side: 'right' });
            }
            else {
                return __assign(__assign({}, this.ballot.response1), { side: 'left' });
            }
        }
    }
});
