import Vue from 'vue';
export default Vue.extend({
    props: {
        data: Object
    },
    methods: {
        setItem: function (key, value) {
            return window.localStorage.setItem(key, value);
        },
        getItem: function (key) {
            return JSON.parse(window.localStorage.getItem(key) || "" + 0);
        },
        votes: function () {
            var value = 0;
            if (this.data.question !== this.getItem('votes_question_num') && this.data.votes > 0) {
                this.setItem('votes_num', JSON.stringify(this.data.votes));
                this.setItem('votes_question_num', JSON.stringify(this.data.question));
            }
            else if (this.data.question === this.getItem('votes_question_num') && this.getItem('votes_num') !== this.data.votes) {
                value = this.data.votes;
                window.localStorage.removeItem('votes_num');
            }
            return value;
        }
    },
    computed: {
        ballots: function () {
            if (this.data.users > this.data.ballots) {
                return this.data.users;
            }
            else {
                return this.data.ballots;
            }
        }
    }
});
