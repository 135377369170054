var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var PING = JSON.stringify({ action: 'ping' });
// The number of times to attempt the ping-pong before giving up.
var MAX_ATTEMPTS = 3;
// How long to wait for a pong after sending a ping.
var PONG_TIMEOUT = 5000;
// The interval at which to send pings. Note that connections will timeout after 10min of inactivity
// so the interval should be shorter than that.
// Here we choose 9min 40s because above we have specified that each time we attempt to ping-pong,
// we may make up to 3 attempts, each of which waits 5 seconds to receive a pong which, in total
// may take up to 3*5=15 seconds. Adding an extra 5 second safety margin, we allow a total of 20s
// for the ping-pong operation. Subtracting 20s from 10min (i.e. the maximum amount of time allowed
// to pass between WS messages before the connection times out) gives use 9m 40s, or 580000ms.
var INTERVAL = 580000;
/**
 * This function should be called as soon as the WS connection is opened. It is responsible for
 * periodically sending a ping and processing a pong response.
 *
 * @param ws
 */
export function initiatePingPongLoop(ws) {
    console.log('initiating ping pong loop');
    setInterval(initiatePingPong, INTERVAL, ws);
}
/**
 * This function sends a ping and waits for a pong. If no pong is received after PING_TIMEOUT ms,
 * another ping will be sent, up to MAX_ATTEMPT times.
 *
 * @param ws
 */
function initiatePingPong(ws) {
    return __awaiter(this, void 0, void 0, function () {
        var _loop_1, i, state_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _loop_1 = function (i) {
                        var callback, pongPromise, timeout, pongReceived;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    callback = function () { };
                                    pongPromise = new Promise(function (resolve) {
                                        ws.send(PING);
                                        // For each message, if its action is "pong" then resolve the promise to true.
                                        callback = function (event) {
                                            var message = JSON.parse(event.data);
                                            if (message.action === 'pong') {
                                                resolve(true);
                                            }
                                        };
                                        // Register the callback as a listener on the WS connection.
                                        ws.addEventListener('message', callback);
                                    });
                                    timeout = new Promise(function (resolve) { return setTimeout(function () { return resolve(false); }, PONG_TIMEOUT); });
                                    return [4 /*yield*/, Promise.race([pongPromise, timeout])
                                        // Deregister the callback.
                                    ];
                                case 1:
                                    pongReceived = _a.sent();
                                    // Deregister the callback.
                                    ws.removeEventListener('message', callback);
                                    // If a pong was received, return. Otherwise, continue with the for loop.
                                    if (pongReceived)
                                        return [2 /*return*/, { value: void 0 }];
                                    return [2 /*return*/];
                            }
                        });
                    };
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < MAX_ATTEMPTS)) return [3 /*break*/, 4];
                    return [5 /*yield**/, _loop_1(i)];
                case 2:
                    state_1 = _a.sent();
                    if (typeof state_1 === "object")
                        return [2 /*return*/, state_1.value];
                    _a.label = 3;
                case 3:
                    i++;
                    return [3 /*break*/, 1];
                case 4:
                    // This line is only reached if all ping-pong attempts have failed.
                    // TODO handle failed ping-pong
                    console.log('failed all ping-pong attempts');
                    return [2 /*return*/];
            }
        });
    });
}
