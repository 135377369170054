import Vue from 'vue';
export default Vue.extend({
    props: {
        data: Object
    },
    methods: {
        setItem: function (key, value) {
            return window.localStorage.setItem(key, value);
        },
        getItem: function (key) {
            return JSON.parse(window.localStorage.getItem(key) || "" + 0);
        },
        responses: function () {
            var value = 0;
            if (this.data.question !== this.getItem('response_question_num') && this.data.responses > 0) {
                this.setItem('responses_num', JSON.stringify(this.data.responses));
                this.setItem('response_question_num', JSON.stringify(this.data.question));
            }
            else if (this.data.question === this.getItem('response_question_num') && this.getItem('responses_num') !== this.data.responses) {
                value = this.data.responses;
                window.localStorage.removeItem('responses_num');
            }
            return value;
        }
    }
});
