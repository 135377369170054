<template>
    <div>
        <button class="btn btn-light mb-2" @click="popup">
            View in New Window
        </button>
    </div>
</template>

<script>
export default {
  methods: {
    popup () {
      const key = `cjcrs:results:${this.results.code}`
      window.sessionStorage.removeItem(key)
      const state = this.results.results.state
      const cjQuestions = this.results.results.cj_questions
      const questions = []
      const responsesData = {}
      for (let i = 0; i < cjQuestions.length; i++) {
        const question = cjQuestions[i]
        const id = question.id
        const prompt = question.prompt
        questions.push({ id, prompt })
        const responses = []
        for (let a = 0; a < question.responses.length; a++) {
          const text = question.responses[a].text
          const score = question.responses[a].score
          const question_id = question.responses[a].question_id
          responses.push({ text, score, question_id })
        }
        responsesData[id] = responses
      }
      window.sessionStorage.setItem(`${key}:state`, JSON.stringify(state))
      window.sessionStorage.setItem(`${key}:cj_questions`, JSON.stringify(questions))
      for (const id in responsesData) {
        if (Object.prototype.hasOwnProperty.call(responsesData, id)) {
          window.sessionStorage.setItem(`${key}:cj_questions:${id}`, JSON.stringify(responsesData[id]))
        }
      }
      const url = '/show'
      window.open(
              `${url}?code=${this.results.code}`,
              `results-${this.results.code}`,
              'popup,scrollbars=on'
      )
    }
  },
  props: {
    results: {
      type: Object,
      required: true
    }
  }
}
</script>
