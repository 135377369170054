import Vue from 'vue';
import axios from 'axios';
import ws from '../../ws';
var util = require('util');
export default Vue.extend({
    name: 'activity',
    data: function () {
        return {
            activity: this.value,
            is_editing: false,
            question_to_edit: {},
            subdomain: process.env.MIX_DOMAIN + '?activityCode=' + this.value.code,
            settings_modified: false,
            ws: ws,
            currentState: {}
        };
    },
    props: {
        value: Object,
        is_viewing: Boolean,
        is_creating: Boolean,
        userEmail: String
    },
    beforeMount: function () {
        this.ws.addEventListener('message', function (message) {
            var data = JSON.parse(message.data);
            console.debug('incoming ws message:', data);
            switch (data.action) {
                case 'redirect':
                    window.location.href = data.payload.url;
                    break;
            }
        });
    },
    computed: {
        swalWithBootstrapButtons: function () {
            return Vue.swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger btn-sm mr-3'
                },
                buttonsStyling: false,
                allowOutsideClick: false,
                allowEscapeKey: true
            });
        },
        is_ready: function () {
            return this.$data.activity.state === 'ready';
        },
        has_started_now: function () {
            // this computed property will disable all buttons if the state is active
            return this.$data.activity.state === 'active';
        },
        is_finished: function () {
            return this.$data.activity.state === 'finished';
        },
        save_data: function () {
            return {
                title: this.$data.activity.title,
                yn_participant_login_required: this.$data.activity.yn_participant_login_required
            };
        }
    },
    methods: {
        start: function () {
            var _this = this;
            axios.post("/activities/" + this.activity.id + "/start")
                .then(function (response) { window.location.href = response.data.url; })
                .catch(function (_a) {
                var response = _a.response;
                // show error message
                var message = "" + response.data.message;
                // if error has specific validation errors, add them to the message
                if ('errors' in response.data) {
                    var sep_1 = '<br/>';
                    var errors = Object.keys(response.data.errors).map(function (key) {
                        return response.data.errors[key].join(sep_1);
                    }).join(sep_1);
                    message += "" + sep_1 + errors;
                }
                _this.alertError(message);
            });
        },
        onEdit: function () {
            this.is_editing = !this.is_editing;
        },
        onUpdate: function () {
            var _this = this;
            axios.put('/activities/' + this.activity.id, this.save_data)
                .then(function (response) {
                if (response.data.status === 'error') {
                    _this.alertWarning(response.data.message);
                }
                else {
                    _this.alertSuccess('Activity updated!');
                    _this.is_editing = false;
                }
            }, function (error) {
                var data = error.data;
                _this.alertError(data.message);
            });
        },
        onSave: function () {
            var _this = this;
            axios.post('/activities', this.save_data)
                .then(function (response) {
                _this.alertSuccess(response.data.message, response.data.url);
                _this.is_editing = false;
            }, function (error) {
                _this.alertWarning(error.response.data.message);
            });
        },
        onCancelEdit: function () {
            this.is_editing = !this.is_editing;
        },
        onDuplicate: function (ynIncludeCollabs) {
            var _this = this;
            axios.post('/activities/' + this.activity.id + '/duplicate', { yn_include_collabs: ynIncludeCollabs })
                .then(function (response) {
                _this.alertSuccess(response.data.message, response.data.url);
            }, function (error) {
                _this.alertError(error.message);
            });
        },
        onShare: function () {
            var _this = this;
            // Get the input
            var copyText = document.getElementById('copyToClipboard');
            // copy value to clipboard (NOTE: this does not work on IE)
            navigator.clipboard.writeText(copyText.value)
                .then(function () { return _this.alertSuccess('Copied to clipboard!'); })
                .catch(function () { return _this.alertError('Could not copy to clipboard.'); });
        },
        onDeleteActivity: function (value) {
            var _this = this;
            this.swalWithBootstrapButtons.fire({
                background: 'white',
                iconColor: 'warning',
                title: '',
                html: '<div class="text-left">Are you sure you want to delete this activity with all related questions and collaborators?</div>',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then(function (result) {
                if (result.isConfirmed) {
                    var url = util.format('/activities/%s', value.id);
                    axios.delete(url)
                        .then(function (response) {
                        _this.alertSuccess('Activity deleted!', response.data.url);
                    }, function (error) {
                        var data = error.data;
                        _this.alertError(data.message);
                    });
                }
                else {
                    _this.alertCancelled();
                }
            });
        },
        /**
         * Collaborator Methods
         */
        addCollaborator: function (user) {
            this.activity.users.push(user);
        },
        removeCollaborator: function (collaborator) {
            var _this = this;
            this.swalWithBootstrapButtons.fire({
                background: 'white',
                iconColor: 'warning',
                title: '',
                html: '<div class="text-left">Do you wish to remove <span class="font-weight-bold">' + collaborator.fullname + '</span> from the collaborators?</div>',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then(function (result) {
                if (result.isConfirmed) {
                    var url = util.format('/collaborators/user'); // we have the wrong url here
                    axios.post(url, { activityId: _this.activity.id, userId: collaborator.id })
                        .then(function () {
                        var i = _this.activity.users.findIndex(function (user) { return (user.id === collaborator.id); });
                        console.log(i, _this.activity.users, collaborator);
                        if (i >= 0)
                            _this.activity.users.splice(i, 1);
                        _this.alertSuccess('Collaborator removed!');
                    }, function (error) {
                        var data = error.data;
                        _this.alertError(data.message);
                        console.log(data);
                    });
                }
                else {
                    _this.alertCancelled();
                }
            });
        },
        /**
         * (CJ) Question Methods
         */
        newQuestion: function () {
            this.question_to_edit = {
                id: 0,
                prompt: '',
                criteria: '',
                yn_synchronized: false,
                max_ballots: 1
            };
        },
        editQuestion: function (question) {
            this.question_to_edit = question;
        },
        addQuestion: function (question) {
            this.activity.cj_questions.push(question);
        },
        updateQuestion: function (updated) {
            var i = this.activity.cj_questions.findIndex(function (question) { return (question.id === updated.id); });
            // simple array syntax (i.e. `arr[i] = x`) is not reactive, so use splice to make sure the page updates
            this.activity.cj_questions.splice(i, 1, updated);
        },
        removeQuestion: function (question) {
            var _this = this;
            var msg = util.format('Do you wish to remove the question "%s" from the activity?', question.prompt);
            this.swalWithBootstrapButtons.fire({
                background: 'white',
                iconColor: 'warning',
                title: '',
                html: '<div class="text-left">' + msg + '</div>',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then(function (result) {
                if (result.isConfirmed) {
                    var url = util.format('/questions/%s', question.id);
                    axios.delete(url)
                        .then(function () {
                        var i = _this.activity.cj_questions.findIndex(function (q) { return (q.id === question.id); });
                        if (i >= 0)
                            _this.activity.cj_questions.splice(i, 1);
                        _this.alertSuccess('Question removed!');
                    }, function (error) {
                        var data = error.data;
                        _this.alertError(data.message);
                    });
                }
                else {
                    _this.alertCancelled();
                }
            });
        },
        /**
         * Alert Helpers
         */
        alertSuccess: function (message, url) {
            return Vue.swal.fire({
                icon: 'success',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then(function () {
                if (url) {
                    window.location.href = url;
                }
            });
        },
        alertCancelled: function () {
            Vue.swal.fire({
                icon: 'error',
                title: 'Cancelled',
                showConfirmButton: false,
                timer: 1500
            });
        },
        alertError: function (message) {
            Vue.swal.fire({
                icon: 'error',
                title: 'Error',
                html: message
            });
        },
        alertWarning: function (message) {
            Vue.swal.fire({
                icon: 'warning',
                title: 'Validation',
                html: message
            });
        },
        accessCodePopup: function () {
            // open window in popup
            var url = '/activities/' + this.activity.id + '/code';
            window.open("" + url, "code-" + this.value.code, 'popup');
        },
        createLocalStorageItem: function () {
            localStorage.setItem('hasReloaded', 'true');
        },
        endForcefully: function () {
            this.currentState.activityUuid = this.activity.id + '';
            this.currentState.name = 'show_results';
            this.currentState.question = 0;
            this.send({
                action: 'end_activity',
                stateProps: this.currentState
            });
        },
        // ws helpers
        send: function (message) {
            console.debug('outgoing ws message', message);
            this.ws.send(JSON.stringify(message));
        }
    },
    mounted: function () {
        localStorage.clear();
        sessionStorage.clear();
    }
});
