var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import Vue from 'vue';
export default Vue.extend({
    props: {
        data: Object
    },
    computed: {
        results: function () {
            // make a deep copy of the array by destructuring so we don't mutate the prop & cast the
            // result as an array of Result objects so typescript knows what properties we have
            return __spreadArrays(this.data.results);
        }
    },
    methods: {
        popup: function () {
            // 1. save results to session storage (cjcrs:results:{code})
            var key = "cjcrs:results:" + this.data.code;
            window.sessionStorage.removeItem(key);
            window.sessionStorage.setItem(key, JSON.stringify(this.data));
            console.log('did this');
            // 2. open window in popup (NOTE: behaves differently depending on user-agent)
            var url = '/show';
            window.open(url + "?code=" + this.data.code, "results-" + this.data.code, 'popup,scrollbars=on');
        }
    }
});
