var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from 'vue';
var State;
(function (State) {
    State[State["Loading"] = 0] = "Loading";
    State[State["Loaded"] = 1] = "Loaded";
    State[State["Error"] = 2] = "Error";
})(State || (State = {}));
export default Vue.extend({
    name: 'share-results',
    data: function () {
        return {
            code: null,
            states: State,
            state: State.Loading,
            results: {
                state: null,
                cj_questions: []
            },
            resultsInActivity: []
        };
    },
    created: function () {
        // find the activity code & set window title
        var params = new URLSearchParams(window.location.search);
        this.code = params.get('code');
        document.title = "Activity Results: " + this.code;
    },
    mounted: function () {
        var _this = this;
        var _a;
        try {
            var state = JSON.parse(window.opener.sessionStorage.getItem("cjcrs:results:" + this.code + ":state"));
            if (state === 'finished') {
                var cjQuestions = JSON.parse(window.opener.sessionStorage.getItem("cjcrs:results:" + this.code + ":cj_questions"));
                var cjQuestionsChunks = cjQuestions.map(function (question) {
                    var responsesChunks = JSON.parse(window.opener.sessionStorage.getItem("cjcrs:results:" + _this.code + ":cj_questions:" + question.id));
                    return __assign(__assign({}, question), { responses: [].concat.apply([], responsesChunks) });
                });
                this.results = { state: state, cj_questions: cjQuestionsChunks };
            }
            else {
                var results = (_a = window.opener.sessionStorage.getItem("cjcrs:results:" + this.code)) !== null && _a !== void 0 ? _a : '';
                this.resultsInActivity = JSON.parse(results);
            }
            this.state = State.Loaded;
        }
        catch (_b) {
            console.error("could not load data for \"cjcrs:results:" + this.code + "\"");
            this.state = State.Error;
        }
    }
});
