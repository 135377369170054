/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import './bootstrap';
import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// import VueSlideoutPanel from 'vue2-slideout-panel';
// install BootstrapVue & optional icon plugin
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
// Vue.use(VueSlideoutPanel)
Vue.use(VueSweetalert2);
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "vue-basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <vue-example-component></example-component>
 */
var files = require.context('./', true, /\.vue$/i);
files.keys().map(function (key) { return Vue.component("Vue" + key.split('/').pop().split('.')[0], files(key).default); });
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
new Vue({
    el: '#vue-container'
});
