import Vue from 'vue';
export default Vue.extend({
    data: function () {
        return {
            ballots: false
        };
    },
    props: {
        response: Object
    }
});
