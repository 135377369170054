var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from 'vue';
import { startCase, camelCase, upperFirst } from 'lodash';
export default Vue.extend({
    data: function () {
        return {
            advancing: true,
            elapsed: '00:00:00'
        };
    },
    props: {
        activity: Object,
        currentState: Object,
        startTime: Number,
        data: Object
    },
    computed: {
        init: function () {
            return Object.keys(this.currentState).length > 0;
        },
        currentStateName: function () {
            return startCase(this.currentState.name);
        },
        currentStateComponent: function () {
            return 'Vue' + upperFirst(camelCase(this.currentStateName));
        },
        question: function () {
            return this.activity.cj_questions[this.currentState.question];
        },
        stateData: function () {
            switch (this.currentState.name) {
                case 'show_results': return {
                    results: this.data.results,
                    question: this.question.prompt,
                    code: this.activity.code
                };
                case 'accept_votes': return __assign(__assign({}, this.data.counts), { sync: this.question.yn_synchronized, question: this.currentState.question });
                case 'accept_responses': return __assign(__assign({}, this.data.counts), { question: this.currentState.question });
                default: return this.data.counts;
            }
        }
    },
    watch: {
        currentStateName: function (newState, oldState) {
            if (this.init && newState !== oldState)
                this.advancing = false;
        },
        // if start time changes, tick immediately
        startTime: function (time) {
            if (time)
                this.tick();
        }
    },
    methods: {
        next: function () {
            if (this.data.counts.users < 3) {
                if (this.currentState.name === 'show_results' && this.currentState.question === this.activity.states[this.activity.states.length - 1].question) {
                    this.$emit('next');
                    this.advancing = true;
                }
                else {
                    this.alertError('There must be at least 3 participants to proceed.');
                }
            }
            else if (this.currentState.name === 'accept_responses') {
                this.showConfirmation('By selecting "Accept," the activity will progress to the <strong>Voting Stage</strong>.<br>' +
                    'Below are the statistics at the time of selecting the Advance button:<br>' +
                    '<strong>Active Connections: ' + this.data.counts.users + '</strong><br>' +
                    '<strong>Responses Submitted: ' + this.data.counts.responses + '</strong>');
            }
            else if (this.currentState.name === 'accept_votes') {
                this.showConfirmation('By selecting "Accept," the activity will progress to display the <strong>Voting Results</strong>.<br>' +
                    'Below are the statistics at the time of selecting the Advance button:<br>' +
                    '<strong>Active Connections: ' + this.data.counts.users + '</strong><br>' +
                    '<strong>Votes Submitted: ' + this.data.counts.votes + '/' + this.data.counts.ballots + ' (' +
                    (this.data.counts.ballots > 0 ? (this.data.counts.votes / this.data.counts.ballots * 100).toFixed(2) : '-') + '%)' + '</strong>');
            }
            else {
                this.$emit('next');
                // deactive 'next' button until state transition
                this.advancing = true;
            }
        },
        tick: function () {
            var _a, _b;
            // if no/null start time, set elapsed to 0
            if (!this.startTime) {
                this.elapsed = '00:00:00';
            }
            else {
                // we can't use Date's toTimeString method because that will be localized; instead we make a new date from the
                // difference and parse its ISO-formatted string (since it's always UTC & a predictable easy-to-parse format):
                //  YYYY-MM-DDTHH:mm:ss.sssZ
                var diff = (_b = (_a = new Date(Date.now() - this.startTime).toISOString()
                    .match(/T(?<time>.*)\.\d+Z/)) === null || _a === void 0 ? void 0 : _a.groups) === null || _b === void 0 ? void 0 : _b.time;
                // if no match for whatever reason, use assume no time has passed
                this.elapsed = diff !== null && diff !== void 0 ? diff : '00:00:00';
            }
        },
        alertError: function (message) {
            Vue.swal.fire({
                icon: 'error',
                title: 'Error',
                html: message
            });
        },
        showConfirmation: function (message) {
            var _this = this;
            Vue.swal.fire({
                icon: 'question',
                html: message,
                showCancelButton: true,
                confirmButtonText: 'Accept',
                cancelButtonText: 'Cancel'
            }).then(function (result) {
                if (result.value) {
                    _this.$emit('next');
                    _this.advancing = true;
                }
            });
        }
    },
    mounted: function () {
        // tick once now, then again every second(ish)
        setInterval(this.tick, 1000);
    }
});
