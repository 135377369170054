import Vue from 'vue';
import axios from 'axios';
export default Vue.extend({
    name: 'question',
    data: function () {
        return {
            question: this.value,
            processing: false
        };
    },
    props: {
        id: String,
        activityId: Number,
        value: Object
    },
    computed: {
        is_adding: function () {
            return this.$data.question.id <= 0;
        }
    },
    watch: {
        value: function () {
            this.question = Object.assign({}, this.value);
        }
    },
    methods: {
        close: function () {
            this.$bvModal.hide(this.id);
        },
        save: function () {
            var _this = this;
            this.processing = true;
            var payload = {
                activity_fk: this.activityId,
                prompt: this.question.prompt,
                criteria: this.question.criteria,
                yn_synchronized: this.question.yn_synchronized,
                max_ballots: this.question.max_ballots || 1
            };
            axios.post('/questions', payload)
                .then(function (response) {
                if (response.data.status === 'error') {
                    _this.alertWarning(response.data.message);
                }
                else {
                    _this.$emit('add', response.data);
                    _this.close();
                }
            })
                .catch(function (error) { return _this.alertError(error.response.data.message); });
            setTimeout(function () {
                _this.processing = false;
            }, 2000);
        },
        update: function () {
            var _this = this;
            var payload = {
                id: this.question.id,
                prompt: this.question.prompt,
                criteria: this.question.criteria,
                yn_synchronized: this.question.yn_synchronized,
                max_ballots: this.question.max_ballots || 1
            };
            axios.put('/questions/' + this.question.id, payload)
                .then(function (response) {
                if (response.data.status === 'error') {
                    _this.alertWarning(response.data.message);
                }
                else {
                    _this.$emit('update', response.data);
                    _this.close();
                    Vue.swal.fire({
                        icon: 'success',
                        title: 'Updated!',
                        showConfirmButton: false,
                        timer: 1000
                    });
                }
            })
                .catch(function (error) { return _this.alertError(error.response.data.message); });
        },
        alertError: function (message) {
            Vue.swal.fire({
                icon: 'error',
                title: 'Error',
                html: message,
                showConfirmButton: false,
                timer: 1000
            });
        },
        alertWarning: function (message) {
            Vue.swal.fire({
                icon: 'warning',
                title: 'Validation',
                html: message
            });
        }
    }
});
