import Vue from 'vue';
import axios from 'axios';
import { debounce } from 'lodash';
export default Vue.extend({
    name: 'add-collaborator',
    data: function () {
        return {
            loading: true,
            term: '',
            results: [],
            user: {}
        };
    },
    props: {
        activityId: Number,
        id: String
    },
    computed: {
        selected: function () {
            return Object.keys(this.$data.user).length > 0;
        }
    },
    watch: {
        // the weird 'this.$data' syntax is necessary to appease TS since the data type Vue
        // doesn't have a 'term' or 'results' property
        term: debounce(function () {
            var _this = this;
            this.$data.loading = true;
            this.$data.results = [];
            // send search request to backend
            if (this.$data.term !== '') {
                // note that if the request returns an error, we don't do anything with it (simply display "no search results")
                axios.get('/collaborators/search', { params: { term: this.$data.term, activityId: this.$props.activityId } })
                    .then(function (response) { _this.$data.results = response.data; })
                    .finally(function () { _this.$data.loading = false; });
            }
        }, 500)
    },
    methods: {
        clear: function () {
            this.user = {};
        },
        select: function (user) {
            this.user = user;
            this.term = user.fullname;
        },
        close: function () {
            this.$bvModal.hide(this.id);
        },
        add: function () {
            var _this = this;
            // if no user selected, stop now
            if (!this.selected)
                return;
            // otherwise send store request
            axios.post('/collaborators', { activityId: this.activityId, userId: this.$data.user.id })
                .then(function (response) {
                if (response.data.status === 'warning') {
                    _this.alertWarning(response.data.message);
                }
                else {
                    _this.$emit('add', _this.user);
                    _this.term = '';
                    _this.user = {};
                    _this.close();
                }
            })
                .catch(function (error) { return _this.alertError(error.response.data.message); });
        },
        alertError: function (message) {
            Vue.swal.fire({
                icon: 'error',
                title: 'Error',
                html: message
            });
        },
        alertWarning: function (message) {
            Vue.swal.fire({
                icon: 'warning',
                title: 'Validation',
                html: message
            });
        }
    }
});
