import Vue from 'vue';
export default Vue.extend({
    data: function () {
        return {
            fields: [
                { key: 'rank', sortable: true },
                { key: 'score', sortable: true },
                { key: 'response' },
                { key: 'ballots', label: '# Ballots', sortable: true, thClass: 'text-nowrap' }
            ]
        };
    },
    props: {
        responses: Array
    },
    computed: {
        tableData: function () {
            return this.responses.map(function (r, i) {
                var _a;
                return {
                    id: r.id,
                    rank: i + 1,
                    score: r.score,
                    response: r.text,
                    ballots: (_a = r.ballots) !== null && _a !== void 0 ? _a : []
                };
            })
                .filter(function (r) { return r.score !== undefined && r.score > 0; })
                .sort(function (a, b) {
                if (b.score === undefined || a.score === undefined)
                    return 0;
                return b.score - a.score;
            }).map(function (r, i) {
                if (i < 10)
                    return r;
                return null;
            }).filter(function (r) { return r !== null; });
        }
    }
});
