import Vue from 'vue';
import { groupBy, startCase } from 'lodash';
export default Vue.extend({
    props: {
        activity: Object,
        currentState: Object
    },
    computed: {
        states: function () {
            return groupBy(this.activity.states.map(function (state) {
                state.title = startCase(state.name);
                return state;
            }), 'question');
        },
        subdomain: function () {
            return process.env.MIX_DOMAIN + '?activityCode=' + this.activity.code;
        }
    },
    methods: {
        onShare: function () {
            var _this = this;
            // Get the input
            var copyText = document.getElementById('copyToClipboard');
            // copy value to clipboard (NOTE: this does not work on IE)
            if (window.isSecureContext && navigator.clipboard) {
                navigator.clipboard.writeText(copyText.value)
                    .then(function () { return _this.alertSuccess('Copied to clipboard!'); })
                    .catch(function () { return _this.alertError('Could not copy to clipboard.'); });
            }
        },
        alertSuccess: function (message, url) {
            return Vue.swal.fire({
                icon: 'success',
                title: message,
                showConfirmButton: false,
                timer: 1500
            }).then(function () {
                if (url) {
                    window.location.href = url;
                }
            });
        },
        alertError: function (message) {
            Vue.swal.fire({
                icon: 'error',
                title: 'Error',
                html: message
            });
        },
        accessCodePopup: function () {
            // open window in popup
            var url = '/activities/' + this.activity.id + '/code';
            window.open("" + url, "code-" + this.activity.code, 'popup');
        }
    }
});
